<template>
  <page-view>
    <div class="user_wrapper">
      <a-tabs @change="changeQueryType" :activeKey="activeKey">
        <a-tab-pane key="0" :tab="`全部（${totalNum}）`"></a-tab-pane>
        <a-tab-pane key="true" tab="付费"></a-tab-pane>
        <a-tab-pane key="false" tab="普通用户"></a-tab-pane>
        <a-input slot="tabBarExtraContent" placeholder="距离最后付费时间天数" style="width:180px;margin-right:12px" v-model.trim="lastPayDay" />
        <a-input slot="tabBarExtraContent" placeholder="用户ID" class="query_input" v-model.trim="queryParam.uid" />
        <a-input slot="tabBarExtraContent" placeholder="用户名" class="query_input" v-model.trim="queryParam.username" />
        <a-button
          slot="tabBarExtraContent"
          type="primary"
          class="query_btn"
          @click="
            () => {
              $refs.table.refresh(false);
            }
          "
          >查询</a-button
        >
        <a-button slot="tabBarExtraContent" @click="resetQuery">重置</a-button>
      </a-tabs>
      <s-table style="margin:0 24px" ref="table" rowKey="id" :columns="columns" :data="dataSource">
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
        <template slot="uid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span class="uid_fat" @mouseover="handleMouseover(record)" @mouseout="handleMouseout(record)" @click="copyInfo(record.uid)">
              {{ text.substring(0, 8) + '……' + text.substring(24) }}
              <span class="uid_child" v-show="record.showid">{{ text }}</span>
            </span>
            <input type="text" class="input2" />
          </a-tooltip>
        </template>
        <template slot="pay" slot-scope="text">
          <span v-if="text">是</span>
          <span v-else>否</span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a @click="getUserByUid(record.uid)">查看联系方式</a>
        </template>
      </s-table>
      <a-modal title="查看联系方式" :width="560" :visible="user.show" @ok="() => (user.show = false)" @cancel="() => (user.show = false)">
        <p>用户ID：{{ user.detail.id }}</p>
        <p>用户昵称：{{ user.detail.username }}</p>
        <p>用户手机号：{{ user.detail.mobile }}</p>
      </a-modal>
    </div>
  </page-view>
</template>

<script>
import { STable } from '@/components';
import { sipUserByUid, accountList, accountGet } from '@api';
import { formatDate } from '@/utils/util';
import { PageView } from '@/layouts';
export default {
  data() {
    return {
      totalNum: 0,
      activeKey: '0',
      account: {},
      user: { detail: {}, show: false },
      queryParam: {},
      lastPayDay: '',
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '用户ID',
          dataIndex: 'uid',
          scopedSlots: { customRender: 'uid' }
        },
        {
          title: '用户名',
          dataIndex: 'username'
        },
        {
          title: '推广码',
          dataIndex: 'code'
        },
        {
          title: '是否付费用户',
          dataIndex: 'pay',
          scopedSlots: { customRender: 'pay' }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          customRender: formatDate
        },

        {
          title: '最后付费时间',
          dataIndex: 'lastPayTime',
          customRender: formatDate
        },
        {
          title: '修改时间',
          dataIndex: 'updateTime',
          customRender: formatDate
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: (parameter) => {
        return accountList('oem', Object.assign(parameter, this.queryParam)).then((result) => {
          //用户总数
          this.totalNum = result.data.page.total;

          let filterData = JSON.parse(JSON.stringify(result.data));
          // 如果有最后付费天数筛选
          if (this.lastPayDay) {
            filterData.list = filterData.list.filter((item) => {
              return (new Date().getTime() - item.lastPayTime) / 86400000 < Number(this.lastPayDay);
            });
          }
          //类型筛选
          if (this.activeKey == '0') {
            filterData.list.forEach((item) => (item.showid = false));
            return filterData;
          } else {
            const data2 = JSON.parse(JSON.stringify(filterData));
            data2.list = data2.list.filter((item) => String(item.pay) == this.activeKey);
            data2.list.forEach((item) => (item.showid = false));
            return data2;
          }
        });
      }
    };
  },
  components: {
    STable,
    PageView
  },
  mounted() {
    accountGet().then((res) => {
      this.account = res.data;
    });
  },
  methods: {
    handleMouseover(record) {
      record.showid = true;
    },
    handleMouseout(record) {
      record.showid = false;
    },
    getUserByUid(uid) {
      sipUserByUid(uid).then((res) => {
        this.user.detail = res;
        this.user.show = true;
      });
    },

    //tab切换
    changeQueryType(key) {
      this.activeKey = key;
      this.$refs.table.refresh(false);
    },
    //重置
    resetQuery() {
      this.lastPayDay = '';
      this.activeKey = '0';
      this.queryParam = {};
      this.$refs.table.refresh(false);
    }
  }
};
</script>

<style lang="less" scoped>
.user_wrapper {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  padding-bottom: 24px;
  font-family: PingFang SC;
}
.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}

.query_input {
  width: 240px;
  height: 32px;
  margin-right: 12px;
}
.query_btn {
  margin-right: 12px;
}
//tab栏
/deep/ .ant-tabs-nav .ant-tabs-tab {
  margin: 0 16px 0 16px;
  padding: 16px 12px;
}
/deep/ .ant-tabs {
  margin-right: 24px;
}
/deep/ .ant-tabs-extra-content {
  line-height: 53px;
}
</style>
